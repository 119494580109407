import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 6.5rem 0rem 1rem 0;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
  /* justify-content: space-around; */
  background-color: #ffe402;
  align-content: center;
  z-index: 0;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 3;

  @media (max-width: 685px) {
    visibility: hidden;
  }
`;

export const SubWrapper = styled.div`
  align-content: center;
  justify-content: center;

  a {
    color: #000;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 1rem;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 1040px) {
      font-size: 14px;
    }
    @media (max-width: 1040px) {
      font-size: 13px;
      padding: 0 10px 0 10px;
    }
    @media (max-width: 1040px) {
      font-size: 12px;
      padding: 0 5px 0 5px;
    }

    &:last-child {
      margin-bottom: unset;
    }

    @media (max-width: 960px) {
      color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
    }
  }
`;
