
import eauto from "assets/bilder/e-mobil/KV_AD_5263_rgb.jpg";
import eauto2 from "assets/bilder/e-mobil/KV_AD_8710_rgb.jpg";
import eauto3 from "assets/bilder/e-mobil/KV_IMG_6899_rgb.jpg";

import eins from "assets/bilder/e-mobil/IMG_6908_rgb.jpg";
import zwei from "assets/bilder/e-mobil/IMG_6959_rgb.jpg";
import drei from "assets/bilder/e-mobil/IMG_6978_rgb.jpg";
import vier from "assets/bilder/e-mobil/IMG_6990_rgb.jpg";

export const lastmanagementContent = {
  h2: "E-Mobilität",
  subtitle: "Nachhaltige, massgeschneiderte Lösungen für Sie.",
  bgs: [eauto, eauto2, eauto3],

  mainContent: `<p>
    Bleiben Sie mit Ihrem Elektrofahrzeug uneingeschränkt mobil. 
    Mit uns an Ihrer Seite finden wir für Ihren Stromanschluss die ideale Lösung.
     Unser eigens entwickeltes Lastmanagement kann massgeschneidert auf Sie angepasst werden. 
    So haben Sie stehts die perfekte Ausgangslage für Ihre spezifische Infrastruktur.
        <br></br>

        Diese Einrichtung ist für uns so selbstverständlich wie das montieren einer Leuchte. 
        Unabhängig ob die Montage in einer Mehrplatzgarage oder einer einzigen Station stattfindet. 
        Unsere Erfahrung mit derartigen Installationen wird ständig erweitert und den aktuellen Gegebenheiten und Vorschriften angepasst.

        <br></br>

        Mit uns fahren Sie gewappnet in die Zukunft der Elektro Mobilität. 
        Die obligatorische Anmeldung der Ladestation beim Netzbetreiber und entsprechende 
        Ansteuerung übernehmen wir für Sie, wie auch die damit verbundene Programmierung. 

        So ist Ihr Fahrzeug immer optimal versorgt.
            <br></br>

            Für weitere Informationen stehen wir Ihnen jederzeit über unser
            <a href="/kontakt?anliegen=emobility">Kontaktformular</a> zur Verfügung,
            schreiben Sie uns ein <a href="mailto:info@elektro-huwiler.ch">E-Mail</a> oder wir nehmen Ihren Anruf gerne auf
             <a href="tel:0447776544">044 777 65 44</a> entgegen.
    </p>
    `,
  modalImages: [eins, zwei, drei, vier],
};
