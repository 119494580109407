import styled from "styled-components";
import tease from "assets/imgs/sol.jpg";

export const Wrapper = styled.div`
  /* background-image: url(${tease}); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
`;




export const IntroWrapper = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 7;
  left: 0;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  h2 {
  color: #ffe402;
  text-shadow: #000 0px 0px 6px;
  /* -webkit-text-stroke: 1px black; */
  font-size: 8em;
  margin-top: 40px;
  letter-spacing: -3px;
  text-align: center;
  margin-bottom: 0px;

  @media(max-width: 768px) {
    font-size: 7em;
  }
  @media(max-width: 668px) {
    font-size: 6em;
  }
  @media(max-width: 580px) {
    font-size: 5em;
  }
  @media(max-width: 500px) {
    font-size: 4.5em;
  }
  @media(max-width: 450px) {
    font-size: 4em;
  }
  @media(max-width: 390px) {
    font-size: 3.5em;
  }
  @media(max-width: 330px) {
    font-size: 3em;
  }

}
  
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }




  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${({ theme }) => (theme === "light" ? "#212121" : "#fff")};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) => (theme === "light" ? "#707070" : "#e6e6e6")};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;



