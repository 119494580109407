import React from "react";
import { Layout, Seo } from "components/common";
import { Intro } from "components/Intro"
import { IntroSlider } from "components/IntroSlider";

import { MainContent } from "components/MainContent"
import GridModalImages from "components/GridModalImages";

import { lastmanagementContent } from "pagesData/e-mobil";
import { IntroRando } from "components/IntroRandom";

const Lastmanagement = () => (
  <Layout>
    <Seo />
    {/* <IntroSlider {...lastmanagementContent}  /> */}
      <IntroRando {...lastmanagementContent}  />
    {/* <Intro {...lastmanagementContent} /> */}
    <MainContent content={lastmanagementContent.mainContent} />
    <GridModalImages images={lastmanagementContent.modalImages} />
  </Layout>
);

export default Lastmanagement;
